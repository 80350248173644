import { IOption, QueryParams } from "interfaces";
import { encodeDataToUrl, httpApi } from "./app/httpApi";
import { IAsyncSelectOption } from "interfaces/form";

export const displayError = (error: any) => {
  if (typeof error === "object" && error.message) {
    return error.message;
  }
};

export const getPhoneMask = (phone: string) => {
  if (phone.startsWith("+44")) {
    return `${phone.slice(0, 3)} ${phone.slice(3, 7)} ${phone.slice(7)}`;
  }
  return phone;
};

export const getNumberTypeValue = (value: string) => {
  let counterDot = 0;
  let counterMinus = 0;

  const newValue =
    value &&
    value.replace(/[^0-9]|\-|\./g, ($0: any) => {
      if ($0 === "-" && !counterMinus++) return "-";
      if ($0 === "." && !counterDot++) return ".";
      return "";
    });

  return newValue;
};

export const getSelectOptions = (list: IOption[]) => {
  return list?.map(({ name }) => name);
};

export const getUuid = (value: string, fullOptions: IOption[]) => {
  return fullOptions?.find(({ name }) => name === value)?.uuid;
};

export const getItemsForAsyncSelect = async (
  url: string,
  params: QueryParams,
  getLabel: (item: any) => string,
  value: string
) => {
  try {
    const data: any = await httpApi({
      partUrl: `${url}?${encodeDataToUrl(params)}`,
      method: "GET",
    });

    const options: IAsyncSelectOption[] = data?.result.map((item: any) => ({
      label: getLabel(item),
      value: item[value],
    }));

    return {
      options,
      count: data?.count || 0,
    };
  } catch (error) {
    throw error;
  }
};
