import * as React from "react";

type Props = {
  id: number;
};

export const Terms = ({ id }: Props) => {
  switch (id) {
    case 1:
      return (
        <>
          <h3>Scope</h3>
          <p>
            These Account Terms and User Licence <strong>(Terms)</strong> of use
            govern how we set up and operate an Account with Nexus, how you may
            access our services and those of our Affiliates, whether through our
            websites or mobile applications. These Terms, together with our{" "}
            <a
              href="https://www.nexus-dt.com/terms/"
              target="_blank"
              rel="noreferrer"
            >
              Website Terms
            </a>{" "}
            and any other specific service terms on the website or app, form a
            legal agreement with you government you registering using an Account
            with us.{" "}
            <a
              href="https://gstt.jelvix.dev/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Our Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="https://www.nexus-dt.com/cookie-policy"
              target="_blank"
              rel="noreferrer"
            >
              Cookies Policy
            </a>{" "}
            will also apply to your Account and our websites and apps.
            Therefore, it is important that you understand and agree to these
            Terms, so please contact us at{" "}
            <a
              href="mailto:admin@nexus-dt.com"
              target="_blank"
              rel="noreferrer"
            >
              admin@nexus-dt.com
            </a>{" "}
            if you have any queries.
          </p>
          <p>
            By registering an Account you will confirm that you accept these
            Terms and that you agree to comply with them. If you do not agree to
            these Terms of Use, you must not register for an Account with us, or
            use our website or apps.
          </p>
          <p>
            <strong>
              In particular: Please review the sections on{" "}
              <a href="#liability">Our Liability</a> and{" "}
              <a href="#not-responsible">
                We are not responsible for Affiliate products and services
              </a>
              .
            </strong>
          </p>
          <h3>Who we are and how to contact us</h3>
          <p>
            We are Nexus Digital Technology Limited <strong>(Nexus)</strong>.
          </p>
          <p>
            To contact us, including to raise any issues or complaints in
            relation to your Account, or our websites and services, please email{" "}
            <a href="mailto:info@nexus-dt.com" target="_blank" rel="noreferrer">
              info@nexus-dt.com
            </a>
            . You may also use the{" "}
            <a
              href="https://www.nexus-dt.com/contact-us"
              target="_blank"
              rel="noreferrer"
            >
              Contact Us
            </a>{" "}
            tab on our websites or apps.
          </p>
          <h3>Complaints</h3>
          <p>
            If you wish to make a complaint, please contact us using the details
            above, providing your name and preferred contact method, as well as
            the reason for your complaint and any information which you consider
            is relevant.
          </p>
          <p>
            We will endeavour to acknowledge all complaints within 5 days and
            then provide a further full response, if necessary, as soon as
            reasonably possible.
          </p>
          <h3>How we will contact you</h3>
          <p>
            We may contact you via our sites, or using the email or other
            contact details you provide when registering for an Account.
          </p>
          <p>
            We request that you keep all contact details within the Account up
            to date. We will also correct any errors or out of date information
            on your behalf.
          </p>
          <h3>Definitions</h3>
          <p>In these Terms of Use, references to:</p>
          <p>
            <strong>“us”</strong>, <strong>“we”</strong> and{" "}
            <strong>“our”</strong> shall also mean Nexus as set out above;
          </p>
          <p>
            <strong>“you”</strong> and <strong>“your”</strong> means any person
            who registers for an Account with us.
          </p>
          <p>
            <strong>“Account”</strong> means an account with Nexus by which you
            may access our sites and services.
          </p>
          <p>
            <strong>“Affiliate”</strong> shall mean any third party who has been
            approved by and contracted with us, and whose products and services
            are advertised and promoted on our sites;
          </p>
          <p>
            <strong>“site”</strong> shall mean all and/or any of our webpages,
            web-apps and/or mobile applications through which the Account may be
            made available to you, including specifically{" "}
            <a href="https://www.nexus-dt.com" target="_blank" rel="noreferrer">
              www.nexus-dt.com
            </a>
            ; and
          </p>
          <p>
            <strong>“service”</strong> shall mean the relevant service provided
            by any of our sites.
          </p>
          <h3>Access and Changes to these Terms</h3>
          <p>
            These Terms are made available to you on all of our sites. We may
            revise these Terms at any time by amending the relevant page on each
            site. We will let you know about the changes and our reasons for
            them the next time you use our sites, or if they only relate to a
            specific service, we may let you know prior to you using that
            service. Any changes will be deemed accepted and binding if you
            choose to continue using our sites and services.
          </p>
          <p>These terms were most recently updated on 5 February 2021.</p>
        </>
      );
    case 2:
      return (
        <>
          <p>
            A Nexus Account is an account that allows you access to all our
            services, including our sites. By creating an Account you are
            confirming that you have read, and accept, these Terms and{" "}
            <a
              href="https://www.nexus-dt.com/terms/"
              target="_blank"
              rel="noreferrer"
            >
              Website Terms
            </a>{" "}
            or{" "}
            <a
              href="https://gsttapi.jelvix.dev/Nexus_Digital_Technology_End_User_Licence%20Agreement(GSTT).pdf"
              target="_blank"
              rel="noreferrer"
            >
              EULA
            </a>
            , and that you understand and accept the application of our{" "}
            <a
              href="https://gstt.jelvix.dev/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="https://www.nexus-dt.com/cookie-policy"
              target="_blank"
              rel="noreferrer"
            >
              Cookies Policy
            </a>
            .
          </p>
          <p>
            You will be required to set an identification code or password, and
            may be requested to provide another piece of information as part of
            our security procedures. We would recommend that you choose security
            information known only to you and a password of a minimum 8
            characters including both upper case, numbers and special
            characters. You must treat such information as confidential and
            personal to you. You must not disclose it to any third party nor use
            any third party's identification and security information to access
            our site.
          </p>
          <p>
            We have the right to disable any user identification code or
            password at any time, or otherwise suspend your Account, if in our
            reasonable opinion you have failed to comply with any of the
            provisions of these Terms. If you know or suspect that anyone other
            than you knows your user identification code or password, you must
            promptly notify us using the Contacting Us details above.
          </p>
          <p>
            The Account is only available on an individual basis, therefore you
            are not permitted to share your password or secure log in with
            anyone else. You are responsible for the access and use of your
            Account and our services, including keeping secure log in
            confidential. [The Account is available to you if you are resident
            in the United Kingdom and 18 years of age or older].
          </p>
          <h3>Closing or suspending your Account</h3>
          <p>
            You can close your Account at any time and this will also end your
            agreement with us under these Terms. To do this, please go to
            "Closing my Account" section on our Site and confirm the action.
          </p>
          <p>
            Please note, we may retain minimal personal data in accordance with
            our{" "}
            <a
              href="https://gstt.jelvix.dev/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
          <p>
            We may close or suspend your Account if we believe there has been a
            security breach or that your security information has been
            compromised, that you have breached these Terms, that you have used
            our site unlawfully or otherwise not in accordance with the
            requirements detailed in our{" "}
            <a
              href="https://www.nexus-dt.com/terms/"
              target="_blank"
              rel="noreferrer"
            >
              Website Terms
            </a>
            . We will let you know as soon as reasonably possible about any
            Account closure or suspension, and our reasons for doing so.
          </p>
        </>
      );
    case 3:
      return (
        <>
          <h4>Purpose:</h4>
          <p>
            Nexus is here to help you take control of your well-being and
            health, and access relevant information, activities and services. We
            do this through providing you with easy to use and accessible health
            questionnaires and information, creating a central Nexus Record for
            you to collate your information, and providing you with access to
            relevant Affiliate Products.
          </p>
          <h4>Health and well-being articles and information:</h4>
          <p>
            We will provide you with relevant research, articles, publications
            and other third party content, which is relevant and related to your
            Nexus Record or our Services, depending on your Account preferences
            and settings.
          </p>
          <h4>Health and Well-Being Questionnaire:</h4>
          <p>
            When registering for an Account we will request you complete a
            questionnaire focused on your health and well-being. This will be
            kept strictly confidential within your{" "}
            <a href="#nexus-record">Nexus Record</a>, (please see our{" "}
            <a
              href="https://gstt.jelvix.dev/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ) and only used by us, with your permission, to provide you with
            articles and information which may be of interest to you, as well as
            to suggest Affiliates who may offer relevant products and services.
            We may ask additional questions or request you to update information
            from time to time.
          </p>
          <h4 id="nexus-record">Nexus Record:</h4>
          <p>
            your personalised record within your Account in which the results of
            your Health and Well-Being Questionnaire are stored, and activities,
            services, and Affiliate Products you purchase (if you have given
            permission). You may control update, amend and delete your Nexus
            Record in Account settings. The personal data in for Nexus Record
            will only be used in accordance with these Terms or as otherwise
            agreed with you at the time. It is a priority for us that you are in
            control of your health information.
          </p>
          <h4>
            Access to tailored and discounted healthcare and social prescribing
            services:
          </h4>
          <p>
            Within our services we have partnered with selected Affiliates who
            offer relevant products and services for social prescribing and
            general health purposes. See <a href="#affiliates">Affiliates</a>{" "}
            below.
          </p>
          <h3>Ending or changing Services</h3>
          <p>
            We may remove or make changes to the services that are made
            available to you, from time to time. This includes ceasing the
            availability of any or all of our sites or services to you and
            closing our Accounts, at our choice. We will do everything
            reasonably possible to inform you in advance of closing Accounts,
            services and sites.
          </p>
          <p>
            Updates and improvements: We may add functionality, make
            improvements enhancements to our sites and services. Please note
            that some updates to the sites and service may be necessary and
            failure to install them may result in the Account or services no
            longer being available to you.
          </p>
        </>
      );
    case 4:
      return (
        <>
          <p id="affiliates">
            As part of our services, we have partnered with selected companies
            and organisations (our Affiliates) who provide products and services
            that positively impact your health and wellbeing. These Affiliates
            may advertise on our Sites and such adverts may be tailored to you
            if you have given your permission in accordance with our{" "}
            <a
              href="https://www.nexus-dt.com/cookie-policy"
              target="_blank"
              rel="noreferrer"
            >
              Cookies Policy
            </a>{" "}
            within the{" "}
            <a
              href="https://www.nexus-dt.com/#gdpr_cookie_modal"
              target="_blank"
              rel="noreferrer"
            >
              Cookies Settings
            </a>
            .
          </p>
          <p>
            We may also provide relevant links and content on our Affiliates in
            relation to specific services and other content on our Sites.
          </p>
          <p>
            It will also be your decision whether to make contact with an
            Affiliate or find out more about their products or services. This
            will usually be available by clicking a link or advert within our
            sites, in which instance we will share limited information on you
            with the Affiliate so we are able to identify you for the referral.
            Please refer to our{" "}
            <a
              href="https://gstt.jelvix.dev/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{" "}
            for more information.
          </p>
          <p>
            In some instances, the Affiliate or network they are involved with,
            may request your acceptance of cookies in order to manage the
            referral from us to the Affiliate. This will be subject to your
            consent which will be requested at the time.
          </p>
          <h3 id="not-responsible">
            We are not responsible for our Affiliates products and services
          </h3>
          <p>
            Please note that the Affiliates are separate companies and
            organisations to Nexus. Therefore, we are not responsible for their
            products and services. You will be required to purchase the products
            and services on the Affiliates relevant terms of business and share
            any personal data requested in accordance with the Affiliate's
            privacy information.
          </p>
          <p>
            Whilst we commit to using our reasonable care when selecting
            Affiliate, we exclude liability, in so far as permitted in law, in
            relation to the performance and failures of the Affiliates when
            providing you with products and services.
          </p>
        </>
      );
    case 5:
      return (
        <>
          <p id="liability">
            We do not exclude or limit in any way our liability to you where it
            would be unlawful to do so. This includes liability for death or
            personal injury caused by our negligence or the negligence of our
            employees, agents or subcontractors or for fraud or fraudulent
            misrepresentation.
          </p>
          <p>
            If we fail to comply with these Terms, we are responsible for loss
            or damage you suffer that is a foreseeable result of our breaking
            our agreement under these Terms or our failing to use reasonable
            care and skill, but we are not responsible for any loss or damage
            that is not foreseeable. Loss or damage is foreseeable if either it
            is obvious that it will happen or if, at the time the Account was
            opened or site or service used, both we and you knew it might
            happen, for example, if you discussed it with us.
          </p>
          <p>
            We take reasonable care in operation of the sites and performance of
            our services. We are responsible for the information we provide to
            you on our sites and the content we generate, we will also reflect
            accurately any information and content given to us by Affiliates or
            other third parties.
          </p>
          <p>
            However, where we include information and content from our
            Affiliates or other third parties, including where we have included
            information from relevant articles, research and publications, we
            are not responsible for external content and we accept no liability
            for the information (whether accurate or not) that they may contain.
            However, we will clearly list and link to the references we have
            used where applicable.
          </p>
          <p>
            We are not liable for business losses. Our sites and services are
            for domestic and private use. If you use them for any commercial,
            business or resale purpose we will have no liability to you for any
            loss of profit, loss of business, business interruption, or loss of
            business opportunity.
          </p>
          <h3>Accessing our services</h3>
          <p>
            We do not guarantee that your Account or our services will always be
            available or be uninterrupted, nor that our sites, or any content on
            them, will be free from errors or omissions. Please refer to our{" "}
            <a
              href="https://www.nexus-dt.com/terms/"
              target="_blank"
              rel="noreferrer"
            >
              Website Terms
            </a>{" "}
            for information on how we provide our sites and your obligations
            when using them.
          </p>
          <h3>Intellectual property rights</h3>
          <p>
            All intellectual property rights in the sites and the services
            throughout the world belong to us (or our licensors) and the rights
            in these are licensed (not sold) to you. You have no intellectual
            property rights in, or to, our sites or services other than the
            right to use them in accordance with these Terms and{" "}
            <a
              href="https://www.nexus-dt.com/terms/"
              target="_blank"
              rel="noreferrer"
            >
              Website Terms
            </a>
            .
          </p>
          <h3>Your use of our site</h3>
          <p>
            You may use our site only for lawful purposes. You may not use our
            site:
          </p>
          <ul>
            <li>
              in any way that breaches any applicable local, national or
              international law, regulation or code of practice;
            </li>
            <li>
              in any which breaches our{" "}
              <a
                href="https://www.nexus-dt.com/terms/"
                target="_blank"
                rel="noreferrer"
              >
                Website Terms
              </a>
              .
            </li>
            <li>
              in any way that is unlawful or fraudulent, or has any unlawful or
              fraudulent purpose or effect;
            </li>
            <li>
              in any way which infringes our or our licensors' intellectual
              property rights;
            </li>
            <li>
              in any way that infringes the rights of any third party (including
              intellectual property rights and rights to privacy); and/or
            </li>
            <li>
              to transmit, or procure the sending of, any unsolicited or
              unauthorised advertising or promotional material or any other form
              of similar solicitation to any person.
            </li>
          </ul>
          <h3>Personal Information</h3>
          <p>
            By opening an Account and using our services you will be providing
            us with your personal information. We will keep this personal
            information secure and only use it in accordance with our{" "}
            <a
              href="https://gstt.jelvix.dev/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy.
            </a>{" "}
            This is in additional to your rights under data protection laws
            which will still apply in full.
          </p>
          <p>
            If you wish to manage how we process your personal information or
            for us to stop using it, you may close your Account, make a request
            in accordance with our Privacy Policy, or otherwise manage what we
            do with your personal information within Account settings in the
            site.
          </p>
          <p>
            Please note. That even if you request deletion of your personal
            information, we may still be required or permitted to keep some of
            it. Refer to our{" "}
            <a
              href="https://gstt.jelvix.dev/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{" "}
            for more information.
          </p>
        </>
      );
    case 6:
      return (
        <>
          <h3>We may transfer this agreement to someone else</h3>
          <p>
            We may transfer our rights and obligations under these Terms to
            another organisation. We will always tell you in writing if this
            happens and we will ensure that the transfer will not affect your
            rights under the contract.
          </p>
          <h3>You may not transfer your Account</h3>
          <p>
            You may not transfer your rights or your obligations under these
            Terms to another person because the Account is personal to you.
          </p>
          <h3>No rights for third parties</h3>
          <p>
            This agreement does not give rise to any rights under the Contracts
            (Rights of Third Parties) Act 1999 to enforce any term of this
            agreement.
          </p>
          <h3>
            If a court finds part of this contract illegal, the rest will
            continue in force
          </h3>
          <p>
            Each of the paragraphs of these Terms operates separately. If any
            court or relevant authority decides that any of them are unlawful,
            the remaining paragraphs will remain in full force and effect.
          </p>
          <h3>
            Even if we delay in enforcing this contract, we can still enforce it
            later
          </h3>
          <p>
            Even if we delay in enforcing the contract under these Terms, we can
            still enforce it later. If we do not insist immediately that you do
            anything you are required to do under these Terms, or if we delay in
            taking steps against you in respect of your breaking the contract,
            that will not mean that you do not have to do those things and it
            will not prevent us taking steps against you at a later date.
          </p>
          <h3>
            Which laws apply to this contract and where you may bring legal
            proceedings
          </h3>
          <p>
            These Terms are governed by English law and you can bring legal
            proceedings in respect of the products in the English courts. If you
            live in Scotland you can bring legal proceedings in respect of the
            products in either the Scottish or the English courts. If you live
            in Northern Ireland you can bring legal proceedings in respect of
            the products in either the Northern Irish or the English courts.
          </p>
        </>
      );
    default:
      return null;
  }
};
