import * as React from "react";
import s from "./Static.module.scss";

type Props = {
  id: number;
};

export const Privacy = ({ id }: Props) => {
  switch (id) {
    case 1:
      return (
        <>
          <p>
            Welcome to Nexus' privacy policy. Nexus respects your privacy and is
            committed to protecting your personal data. This privacy policy will
            inform you as to how we look after your personal data when you use
            our applications, web-apps, websites (regardless of where you visit
            from), open a Nexus Account with us or otherwise engage with us as
            an Affiliate, Partner or supplier. This Policy will also tell you
            about your privacy rights and how the law protects you. This Privacy
            Policy is provided in a layered format so you can click through to
            the specific areas set out below. Please also use the Glossary to
            understand the meaning of some of the terms used in this privacy
            policy.
          </p>
        </>
      );
    case 2:
      return (
        <>
          <h3>Nexus' commitment to you</h3>
          <p>
            We place the integrity and control of your personal information at
            the centre of the Nexus business and Platforms. As a Nexus User you
            will be able to manage your Nexus Account settings and preferences,
            so that you only receive the information relevant for you and only
            hear about products and services with your permission.
          </p>
          <p>
            We will request information about your health and well-being, for
            the purpose of you being able to better understand your current
            health status, and then be empowered to take decisions on how to
            improve or change this. The collection of this sensitive data is
            always subject to your consent and you may control its use at all
            times- see <a href="#sensitive">Sensitive Data</a>.
          </p>
          <h3>What this Policy is about</h3>
          <p>
            This privacy policy aims to give you information on how Nexus
            collects and processes your personal data through your use of our
            websites (including this{" "}
            <a href="https://www.nexus-dt.com" target="_blank" rel="noreferrer">
              www.nexus-dt.com
            </a>
            ), applications (Nexus Mobile App) and any other web-apps (together
            referred to as our Nexus <strong>Platforms</strong> in this Policy).
            This includes any information you provide when opening a Nexus
            account, answer our Health and Well-being Questionnaire, otherwise
            provide through our Nexus Platforms when you communicate with us.
          </p>
          <p>
            This Policy also explains how and when information may be shared
            with our <a href="#affiliates">Affiliates and Partners</a> if you
            want to engage with them directly and purchase their products or
            services. This Policy also covers our use of your data if you work
            for one of our Affiliates, Partners or suppliers. This policy also
            sets out when we may communicate with you in relation to our
            services and business.
          </p>
          <h3>Not intended for children:</h3>
          <p>
            The Nexus Platforms are not intended for children and we do not
            knowingly collect data relating to children.
          </p>
          <p>
            It is important that you read this privacy policy together with any
            other privacy information or notices we provide, so that you may
            understand how we use and keep your data secure. Please contact us
            at{" "}
            <a href="mailto:info@nexus-dt.com" target="_blank" rel="noreferrer">
              info@nexus-dt.com
            </a>{" "}
            if you have any questions or would like further information.
          </p>
          <h3>Controller</h3>
          <p>
            Nexus Digital is the Data Controller under data protection law, and
            this means we are responsible for determining how and why personal
            information provided to us is processed, as well as keeping it
            secure.
          </p>
          <p>
            We have appointed a data privacy manager who is responsible for
            overseeing questions in relation to this privacy policy. If you have
            any questions about this privacy policy, including any requests to
            exercise your legal rights, please contact the DPO on{" "}
            <a href="mailto:info@nexus-dt.com" target="_blank" rel="noreferrer">
              info@nexus-dt.com
            </a>
          </p>
          <h3>Contact details</h3>
          <p>
            If you have any questions about this privacy policy or our privacy
            practices, please contact our Data Privacy Manager/DPO in the
            following ways:
          </p>
          <p>
            Email address:{" "}
            <a href="mailto:info@nexus-dt.com" target="_blank" rel="noreferrer">
              info@nexus-dt.com
            </a>
          </p>
          <p>
            Postal address: Data Privacy Manager, Nexus Digital Technology Ltd,
            L2-8 Ivy Business Centre Crown Street, Failsworth, Manchester,
            England, M35 9BG
          </p>
          <p>
            You have the right to make a complaint at any time to the
            Information Commissioner's Office (ICO), the UK supervisory
            authority for data protection issues (
            <a href="https://ico.org.uk/" target="_blank" rel="noreferrer">
              www.ico.org.uk
            </a>
            ). We would, however, appreciate the chance to deal with your
            concerns before you approach the ICO so please contact us in the
            first instance.
          </p>
          <h3>
            Changes to the privacy policy and your duty to inform us of changes
          </h3>
          <p>
            We keep our privacy policy under regular review. This version was
            last updated on 5 February 2021.
          </p>
          <p>
            It is important that the personal data we hold about you is accurate
            and current. Please keep us informed if your personal information
            changes and this can be done through your Nexus Account for all
            Nexus Users.
          </p>
        </>
      );
    case 3:
      return (
        <>
          <p>
            Personal data, or personal information, means any information about
            you from which you can be identified. 3 We may collect, use, store
            and transfer different kinds of personal data about you, depending
            on why you are engaging with us:
          </p>
          <h3>Nexus Platforms:</h3>
          <p>
            Each time you use our Nexus Platforms we may automatically collect
            the following information:
          </p>
          <ul>
            <li>
              technical information, including (but not limited to) the type of
              device you use, a unique device identifier, mobile network
              information, your mobile operating system, and time zone setting;
            </li>
            <li>
              information either accessed through your device or stored on your
              device which you have explicitly consented to sharing, including
              where the data comes from and when it was collected; and
            </li>
            <li>
              details of your use of the Nexus Platforms and referrals to
              Affiliates
            </li>
          </ul>
          <h3>Nexus Users</h3>
          <p>
            A Nexus User is someone who has opened an account with Nexus via one
            of the Nexus Platforms (<strong>Nexus Account</strong>). If you are
            a nexus User, we may process the following personal information
            about you:
          </p>
          <ul>
            <li>
              <strong>Identity Data</strong> includes first name, maiden name,
              last name, username or similar identifier, marital status, title,
              date of birth and gender
            </li>
            <li>
              <strong>Contact Data</strong> includes address, email address and
              telephone numbers
            </li>
            <li>
              <strong>Profile Data</strong> includes your basic account
              information; username password and access information; photos you
              may choose to upload; other information you may provide in
              response to a questionnaire or survey; any referrals you choose to
              Affiliates; support queries and complaints raised; your interests,
              preferences and feedback and survey responses; any other
              information you provide to use in relation to your Nexus Account.
            </li>
            <li>
              <strong>Marketing and Communications Data</strong> includes your
              preferences in receiving marketing from us, any third parties and
              your communication preferences, including registering for our
              newsletter.
            </li>
            <li>
              <strong>Health and Well-being Data</strong>
            </li>
          </ul>
          <h3>If you work for an Affiliate, Partner or supplier</h3>
          <p>
            In order to collaborate, receive each other's products or services,
            we may need the following information about you in order to
            communicate with you about your work or organisation.
          </p>
          <ul>
            <li>
              <strong>Identity Data</strong> includes first name, maiden name,
              last name, username or similar identifier, marital status, title,
              date of birth and gender.
            </li>
            <li>
              <strong>Contact Data</strong> includes address, email address and
              telephone numbers.
            </li>
          </ul>
          <h3>Anonymised/Aggregated Data</h3>
          <p>
            We also collect, use and share <strong>Anonymised Data</strong> such
            as statistical or demographic data. Anonymised Data could be derived
            from your personal data but is not considered personal data in law
            as this data will not directly or indirectly reveal your identity.
            For example, we may aggregate your usage information to calculate
            the percentage of users interested in a particular affiliate
            service.
          </p>
          <p>
            We may use Anonymised Data of Nexus Users of our users to better
            understand our products, review health and wellbeing needs and
            patterns more widely, as well as conducting research. This helps us
            provide and tailor products and services which are relevant for
            Nexus Users. For example, we might learn that lots of people in one
            city are interested in running, whereas in another city it is more
            about going to the gym. We might also learn where people have
            certain conditions, for example depression or weight management
            issues.
          </p>
          <p>
            Research organisations, academic institutions and health
            organisations are interested in this kind of data because it helps
            to plan the best services for people in those areas. It is not
            linked to you personally, and we do not identify you.
          </p>
          <p>
            However, if we use, combine or connect Anonymised Data with your
            personal data or other information so that you may be identified
            form it again, we will we treat the combined data as your personal
            data in accordance with this privacy policy.
          </p>
          <h3 id="sensitive">
            Health and Well-being information (Sensitive Data)
          </h3>
          <p>
            <strong>
              Sensitive Data (also known as Special Categories of Personal Data)
            </strong>
            includes details about race or ethnicity, religious or philosophical
            beliefs, sex life, sexual orientation, political opinions, trade
            union membership, information about your health, and genetic and
            biometric data
          </p>
          <p>
            <strong>For Nexus Users</strong>, we do collect Sensitive Data about
            you, when you open a Nexus Account and choose to complete the Health
            and Well-being Questionnaire, or if you respond to further surveys
            and questionnaires as a Nexus User. This is for you to build up your
            Nexus Record with relevant information on your health and wellbeing
            interests and priorities. If you have set your preferences so you
            may receive information on suggested and relevant well-being
            activities and services, we will contact you and keep you informed
            on these. We will also use the information in your Nexus Record to
            help tailor these suggestions to you. You may control your settings
            and these suggestions at any time in the account settings.
          </p>
          <p>
            <strong>Affiliate Products</strong> by their nature may constitute
            Sensitive Data about you, for example if you have purchased a
            treatment for a bad back or bought a blood test, this will be
            medical information about you. We track information on the Affiliate
            Products and web links a with whom you connect, to manage
            transactions and to tailor suggestions of products and services to
            you (if selected). We would also like to add the information on the
            Affiliate Products you purchase or use, so you can build up an
            accurate and up to date Nexus Record covering your health and
            wellbeing. However, your consent will be requested to share such
            Sensitive Data, by the Affiliate or us, before any of the purchase
            or service information is shared and added to your Nexus Record.
          </p>
          <p>
            <strong>Research Projects and Studies</strong> will be made
            available or suggested to you if you have agreed to be contacted
            about these in your Nexus Account settings.
          </p>
          <p>
            If you are not a Nexus User, we do not usually collect Special
            Category Personal Data about you.
          </p>
          <h3>If you fail to provide personal data</h3>
          <p>
            Where we need to collect personal data by law, or under the terms of
            a contract we have with you, and you fail to provide that data when
            requested, we may not be able to perform the contract we have or are
            trying 5 to enter into with you or provide you with our services if
            you have been referred by a client. In this case, we may have to
            cancel a service you have with us, but we will notify you if this is
            the case at the time.
          </p>
        </>
      );
    case 4:
      return (
        <>
          <p>
            We use different methods to collect data from and about you
            including through:
          </p>
          <h4>Direct interactions.</h4>
          <ul>
            <li>open a Nexus Account as a Nexus User;</li>
            <li>
              complete our Health and Wellbeing Questionnaire or other surveys
              and questionnaires;
            </li>
            <li>
              when you email, phone, or communicate with us via Nexus Platforms;
            </li>
            <li>enquire about or discuss our services;</li>
            <li>
              you interact with us and our Affiliates when enquiring about or
              purchasing Affiliate products and services;
            </li>
            <li>subscribe to our newsletter or mailing list;</li>
            <li>request marketing to be sent to you;</li>
            <li>when you supply services or goods to us;</li>
            <li>
              when we partner and collaborate as Affiliates and Partners in
              order to provide and supply products and services;
            </li>
            <li>respond to a survey or provide feedback.</li>
          </ul>
          <h4>Automated technologies or interactions.</h4>
          <p>
            As you interact with our Nexus Platforms, we will automatically
            collect data about your equipment, browsing actions and patterns. We
            collect this personal data by using cookies, server logs and other
            similar technologies. Please see our{" "}
            <a
              href="https://www.nexus-dt.com/cookie-policy"
              target="_blank"
              rel="noreferrer"
            >
              cookie policy
            </a>{" "}
            for further details.
          </p>
          <ul>
            <li>
              <h4>Third parties or publicly available sources.</h4>
              <p>
                We will receive personal data about you from various third
                parties and public sources as set out below.
              </p>
            </li>
            <li>
              From Affiliates and Partners in order to manage collaborations,
              transactions and business interests
            </li>
            <li>
              From Affiliates and Partners with your consent when it relates to
              your Sensitive Data
            </li>
            <li>
              From research or academic organisations when you have agreed to
              take part in a study or trial.
            </li>
            <li>
              <strong>Technical Data</strong> from the following parties:
              <ul>
                <li>Analytics providers such as Google based outside the EU</li>
              </ul>
            </li>
          </ul>
          <h3>Profiling and Automated Decision Making</h3>
          <p>
            Please note, we do not currently use software to automatically
            assess your usage or personal data in order to determine more
            information about you or your characteristics (known as{" "}
            <strong>Profiling</strong>). Neither do we take automated decisions
            about you based on the personal data we hold, which may have a
            significant impact on your rights and freedoms (known as{" "}
            <strong>Automated Decision Making</strong>). We will update this
            Policy and other privacy information in the event this changes.
          </p>
        </>
      );
    case 5:
      return (
        <>
          <h3 id="affiliates">Affiliates:</h3>
          <p>
            We have relationships with selected Affiliates, covered by relevant
            contracts, under which we can suggest and offer relevant Affiliate
            products and services to you. These Affiliates are chosen for their
            relevance and experience in health and well-being services, as well
            as social prescribing. These Affiliate products are made available
            generally on Nexus Platforms, but you may receive personalised and
            tailored information on Affiliate products if you have selected this
            on registration or within Nexus Account settings.
          </p>
          <p>
            When you are interested in an Affiliate product or service you can
            just click the link, image or advert and be directed to their
            website. We track that you have done this, so that we are able to
            understand the products and services you are interested in, so that
            we may better manage our Nexus Platforms and services. However, we
            do not share any personal information with the Affiliates without
            your permission.
          </p>
          <p>
            We may receive commission or charges from our Affiliates if a Nexus
            User purchases and Affiliate product of service. Therefore, we
            receive high-level confirmation of transaction information from an
            Affiliate, so we are able to manage our business and receive
            payments for commission due. This data is the minimum required to
            confirm the purchase and we do not receive any Sensitive Data from
            an Affiliate to do this.
          </p>
          <p>
            Our Affiliates may also request your consent to share information
            with us confirming what purchase you have made and, in some
            circumstances, the outcome or the service or product. This is for
            the benefit of building up your Nexus Record, but will be subject to
            your consent at that time.
          </p>
          <h3>Partners:</h3>
          <p>
            We have relationships with other organisations where we have agreed
            to collaborate or partner with them in relation to our services and
            Nexus Platforms. These are usually either:
          </p>
          <ol type="a">
            <li>
              research, health or academic bodies, or commercial organisations
              with whom we are jointly undertaking a research, trial or study;
              or
            </li>
            <li>
              commercial organisations or health institutions who have adopted
              our services and arranged access to and licences for our Nexus
              Platforms, for their personnel or patients.
            </li>
          </ol>
          <p>
            In relation to Partners, statistical information is usually
            processed on an anonymous or aggregated basis. If we process your
            personal information, it will be because you have opened a Nexus
            Account or have given us your consent at that time.
          </p>
        </>
      );
    case 6:
      return (
        <>
          <p>
            This website may include links to third-party websites, plug-ins and
            applications. Clicking on those links or enabling those connections
            may allow third parties to collect or share data about you. We do
            not control these third-party websites and are not responsible for
            their privacy statements. When you leave our website, we encourage
            you to read the privacy policy of every website you visit.
          </p>
        </>
      );
    case 7:
      return (
        <>
          <h3>On what basis will we process your information?</h3>
          <p>
            We will only use your personal data when the law allows us to. Most
            commonly, we will use your personal data in the following
            circumstances:
          </p>
          <ul>
            <li>
              Where we need to perform the contract we are about to enter into
              or have entered into with you- for example when you open a Nexus
              Account and engage our services.
            </li>
            <li>
              Where it is necessary for our legitimate interests (or those of a
              third party) and your interests and fundamental rights do not
              override those interests- for example tracking the purchases and
              transaction information with our Affiliates. When we rely on
              legitimate interests we will weigh up the use and the reason for
              it, as well as any benefits, alongside your rights and freedoms,
              before we go ahead with the processing.
            </li>
            <li>
              Where we have your consent- for example when we process your
              health and well-being information, or confirm you want to hear
              about relevant activities and services
            </li>
            <li>Where we need to comply with a legal obligation</li>
            <li>Where we need to act to protect your vital interests.</li>
          </ul>
          <h3>Purposes for which we will use your personal data</h3>
          <p>
            We have set out below, in a table format, a description of all the
            ways we plan to use your personal data, and which of the lawful
            bases we rely on to do so. We have also identified what our
            legitimate interests are where appropriate. Note that we may process
            your personal data for more than one lawful ground depending on the
            specific purpose for which we are using your data. Please contact us
            for further information.
          </p>
          <div className={s.tableWrap}>
            <table>
              <thead>
                <tr>
                  <th>Purpose/Activity</th>
                  <th>Type of data</th>
                  <th>Lawful basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>To open your Nexus Account</td>
                  <td>About whom you are and how to contact you.</td>
                  <td>
                    To perform a contract with you (i.e. access our service).
                    Necessary for our legitimate interest
                  </td>
                </tr>
                <tr>
                  <td>
                    To collect information via the Health and Wellbeing
                    Questionnaire and other health surveys
                  </td>
                  <td>
                    Information about you and your health and wellbeing
                    (Sensitive Data)
                  </td>
                  <td>
                    <ol type="a">
                      <li>With your consent.</li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    To provide you with information on relevant activities and
                    services in the well-being sector, including those offered
                    by our Affiliates.
                  </td>
                  <td>
                    Information about you, your interests and likes in relation
                    to health and well-being services.
                  </td>
                  <td>
                    <ol type="a">
                      <li>With your consent</li>
                      <li>
                        Necessary for our legitimate interest in order to
                        provide our services to you and other Nexus Users
                      </li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    To add information on the Affiliate products and services
                    you purchase or use, to your Nexus Profile
                  </td>
                  <td>
                    Information on who you are, your interests and profile
                    information. This may include health and well-being
                    information (Sensitive Data)
                  </td>
                  <td>
                    <ol type="a">
                      <li>
                        With your consent, obtained by the Affiliate or by us
                      </li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    To manage our collaborations with our Partners, which may
                    include providing you with access to our services and Nexus
                    Platforms.
                  </td>
                  <td>
                    Information about you, who you are, where you work and your
                    contact information.
                  </td>
                  <td>
                    <ol type="a">
                      <li>
                        Necessary for our, or our Partners', legitimate interest
                        in providing products.
                      </li>
                      <li>To perform a contract with you as a Nexus User.</li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    To refer, enrol or support you as a participant in a
                    research, health or academic study or trial. To suggest to
                    you studies or trials which you may be interested in.
                  </td>
                  <td>
                    Information on who you are, how to contact you, your
                    interests and profile information. This may include health
                    and wellbeing information (Sensitive Data)
                  </td>
                  <td>
                    <ol type="a">
                      <li>With your consent</li>
                      <li>
                        Necessary for our legitimate interest in order to
                        understand the relevance of such projects, trials and
                        our Partner relationships.
                      </li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      To manage Affiliate relationships, receive services, goods
                      and other supplier inputs, including:
                    </p>
                    <ol type="a">
                      <li>Manage payments, fees and charges</li>
                      <li>
                        Contact information and financial/transaction
                        information if engaging with us on your own behalf.
                      </li>
                    </ol>
                  </td>
                  <td>
                    Information on who you are, how to contact you, your
                    interests and profile information. This may include health
                    and wellbeing information (Sensitive Data)
                  </td>
                  <td>
                    <ol type="a">
                      <li>
                        Performance of a contract with you or your employer
                      </li>
                      <li>
                        Necessary for our legitimate interests (to recover debts
                        due to us)
                      </li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      To manage our relationship with Nexus Users which will
                      include:
                    </p>
                    <ol type="a">
                      <li>
                        Notifying you about changes to our terms or privacy
                        policy
                      </li>
                      <li>Asking you to provide feedback or take a survey</li>
                    </ol>
                  </td>
                  <td>
                    <p>Information about who you are and how to contact you.</p>
                    <p>
                      Information relating to your Account profile and
                      preferences/settings on marketing and communications
                    </p>
                  </td>
                  <td>
                    <ol type="a">
                      <li>Performance of a contract with you</li>
                      <li>Necessary to comply with a legal obligation</li>
                      <li>
                        Necessary for our legitimate interests (to keep our
                        records updated and to study how customers use our
                        products/services)
                      </li>
                      <li>consent in some instances for marketing.</li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    To administer and protect our business and Nexus Platforms
                    (including troubleshooting, fraud prevention, data analysis,
                    testing, system maintenance, support, reporting and hosting
                    of data)
                  </td>
                  <td>
                    Information on who you are, how you use or Nexus Platforms,
                    what devices you use. Information on how you use our
                    services.
                  </td>
                  <td>
                    <ol type="a">
                      <li>
                        Necessary for our legitimate interests (for running our
                        business, improving our services, providing relevant
                        information on activities and services, provision of
                        administration and IT services, network security, to
                        prevent fraud and in the context of a business
                        reorganisation or group restructuring exercise)
                      </li>
                      <li>Necessary to comply with a legal obligation</li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    To deliver relevant Nexus Platform content to you and
                    measure or understand the effectiveness of our website and
                    services. To offer you other services, products and Nexus
                    Platforms
                  </td>
                  <td>
                    Information about who you are your profile with Nexus. This
                    may include health and well-being information (Sensitive
                    Data). Information on how you use our Nexus Platforms
                  </td>
                  <td>
                    <ol type="a">
                      <li>
                        Necessary for our legitimate interests (to study how
                        customers use our products/services, to develop them, to
                        grow our business and to inform our strategy).
                      </li>
                      <li>
                        With your consent when processing your health and
                        well-being information.
                      </li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    To use data analytics to improve Nexus Platforms,
                    products/services, marketing, client relationships.
                  </td>
                  <td>
                    Information about how you use our Nexus Platforms, what
                    devices you use and other technical information.
                  </td>
                  <td>
                    Necessary for our legitimate interests (to define types of
                    customers for our products and services, to keep our website
                    updated and relevant, to develop our business and to inform
                    our strategy)
                  </td>
                </tr>
                <tr>
                  <td>
                    To make suggestions and recommendations to you about
                    services, Affiliate relationships and Partner arrangements
                    that may be of interest to your employer
                  </td>
                  <td>
                    Information about who you are, how to contact you and who
                    you work for.
                  </td>
                  <td>
                    Necessary for our legitimate interests (to develop our
                    products/services and grow our business)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Marketing</h3>
          <p>
            We will use the information in your Nexus Account to propose
            activities, services and products which may be of interest to you,
            if you have agreed to this on registration/in your Account settings.
            Otherwise, we make information on our Affiliate products available
            generally on the Nexus Platforms.
          </p>
          <p>
            We do contact and market to organisations which we believe may be
            interested to be an Affiliate or Partner, or otherwise engage with
            us. As such, we may contact you using your business contact
            information in order to discuss this with you or your employer. We
            rely on our legitimate business interests to do this.
          </p>
          <p>
            You may receive marketing communications from us if you have
            requested information from us or purchased services from us and you
            have not opted out of receiving that marketing.
          </p>
          <p>
            Nonetheless, we strive to provide you with choices regarding any
            marketing communication and you will be able to opt out of any
            communications. We operate a suppression list to ensure we can
            manage this process, which means we keep the minimal personal data
            necessary to ensure we do not contact you again.
          </p>
          <p>
            Nexus Users can manage their communication preferences within the
            Nexus Account settings.
          </p>
          <p>
            Where you opt out of receiving these marketing messages, this will
            not apply to personal data provided to us as a result of service
            purchase or other transactions.
          </p>
          <h3>Third-party marketing</h3>
          <p>
            We do not share your information with Affiliates or other third
            parties for marketing purposes without your prior consent.
          </p>
          <h3>Cookies</h3>
          <p>
            You can set your browser to refuse all or some browser cookies, or
            to alert you when websites set or access cookies. If you disable or
            refuse cookies, please note that some parts of this website may
            become inaccessible or not function properly. For more information
            about the cookies we use, please see the{" "}
            <a
              href="https://www.nexus-dt.com/cookie-policy"
              target="_blank"
              rel="noreferrer"
            >
              Cookies Policy
            </a>
            .
          </p>
          <p>
            Cookies consent may be requested by an Affiliate when you click
            through or link with their product or websites.
          </p>
          <h3>Change of purpose</h3>
          <p>
            We will only use your personal data for the purposes for which we
            collected it, unless we reasonably consider that we need to use it
            for another reason and that reason is compatible with the original
            purpose. If you wish to get an explanation as to how the processing
            for the new purpose is compatible with the original purpose, please
            contact us.
          </p>
          <p>
            10 If we need to use your personal data for an unrelated purpose, we
            will notify you and we will explain the legal basis which allows us
            to do so.
          </p>
          <p>
            Please note that we may process your personal data without your
            knowledge or consent, in compliance with the above rules, where this
            is required or permitted by law.
          </p>
        </>
      );
    case 8:
      return (
        <>
          <p>
            We may share your personal data with the parties set out below for
            the purposes set out in the table above.
          </p>
          <ul>
            <li>
              Internal third parties such as our employees or officers and legal
              entities within the Nexus group of companies.
            </li>
            <li>With Affiliates and Partners.</li>
            <li>
              With research and trial partners, being commercial research,
              health and academic organisations.
            </li>
            <li>
              External third parties including clients, payment providers,
              specialist IT support, cloud hosting providers, communications
              partners and sub-contractors.
            </li>
            <li>
              Professional advisers including lawyers, bankers, marketing
              agencies, auditors and insurers who provide consultancy, banking,
              legal, insurance and accounting services.
            </li>
            <li>
              Credit reference agencies, law enforcement and fraud prevention
              agencies.
            </li>
            <li>
              HM Revenue & Customs, regulators and other authorities who require
              reporting or processing activities in certain circumstances.
            </li>
            <li>
              Third parties to whom we may choose to sell, transfer, or merge
              parts of our business or our assets. Alternatively, we may seek to
              acquire other businesses or merge with them. If a change happens
              to our business, then the new owners may use your personal data in
              the same way as set out in this privacy notice.
            </li>
            <li>
              We require all third parties to respect the security of your
              personal data and to treat it in accordance with the law. We do
              not allow our third-party service providers to use your personal
              data for their own purposes and only permit them to process your
              personal data for specified purposes and in accordance with our
              instructions.
            </li>
          </ul>
        </>
      );
    case 9:
      return (
        <>
          <p>
            We do not usually or regularly transfer data outside of the UK in
            the course of our business. In the event we transfer your personal
            data out of the UK, we will ensure a similar degree of protection is
            afforded to it by ensuring at least one of the following safeguards
            is implemented:
          </p>
          <ul>
            <li>
              We will only transfer your personal data to countries that have
              been deemed to provide an adequate level of protection for
              personal data by the UK Government (including the EEA as at the
              date of this version of the Policy).
            </li>
            <li>
              Where we use certain service providers, we may use specific
              contracts approved for use in the UK which give personal data the
              same protection it has in the UK, as well as conducting an
              appropriate risk assessment.
            </li>
          </ul>
          <h3>Data security</h3>
          <p>
            We have put in place appropriate security measures to prevent your
            personal data from being accidentally lost, used or accessed in an
            unauthorised way, altered or disclosed. In addition, we limit access
            to your personal data to those employees, agents, contractors and
            other third parties who have a business need to 11 know. They will
            only process your personal data on our instructions and they are
            subject to a duty of confidentiality.
          </p>
          <p>
            We have put in place procedures to deal with any suspected personal
            data breach and will notify you and any applicable regulator of a
            breach where we are legally required to do so.
          </p>
          <h3>Data retention</h3>
          <p>How long will you use my personal data for?</p>
          <p>
            We will only retain your personal data for as long as reasonably
            necessary to fulfil the purposes we collected it for, including for
            the purposes of satisfying any legal, regulatory, tax, accounting or
            reporting requirements. We may retain your personal data for a
            longer period in the event of a complaint or if we reasonably
            believe there is a prospect of litigation in respect to our
            relationship with you.
          </p>
          <p>
            To determine the appropriate retention period for personal data, we
            consider the amount, nature and sensitivity of the personal data,
            the potential risk of harm from unauthorised use or disclosure of
            your personal data, the purposes for which we process your personal
            data and whether we can achieve those purposes through other means,
            and the applicable legal, regulatory, tax, accounting or other
            requirements.
          </p>
          <p>
            For Nexus Users we usually retain some of your personal data, which
            may include Sensitive Data, for a period of 6 years after you have
            finished using our services. This is the minimum information
            required to ensure we can provide relevant information to you and
            respond to any queries, complaints or claims.
          </p>
          <p>
            If you are an Affiliate, Partner or other supplier, we usually keep
            information for 6 years after the last purchase or invoice, however,
            though this is usually organisational information and any personal
            data is just retained on an ancillary basis (e.g. your name is on an
            invoice)
          </p>
          <p>
            In some circumstances you can ask us to delete your data: see your
            legal rights below for further information.
          </p>
          <p>
            In some circumstances we will receive your data on an anonymous
            basis from our clients or we will anonymise your personal data (so
            that it can no longer be associated with you) for research or
            statistical purposes, in which case we may use this information
            indefinitely without further notice to you.
          </p>
        </>
      );
    case 10:
      return (
        <>
          <p>
            Under certain circumstances, you have rights under data protection
            laws in relation to your personal data. Please click on the links
            below to find out more about these rights:
          </p>
          <p>
            <strong>Request access</strong> to your personal data (commonly
            known as a "data subject access request"). This enables you to
            receive a copy of the personal data we hold about you and to check
            that we are lawfully processing it.
          </p>
          <p>
            <strong>Request correction</strong> of the personal data that we
            hold about you. This enables you to have any incomplete or
            inaccurate data we hold about you corrected, though we may need to
            verify the accuracy of the new data you provide to us.
          </p>
          <p>
            <strong>Request erasure</strong> of your personal data. This enables
            you to ask us to delete or remove personal data where there is no
            good reason for us continuing to process it. You also have the right
            to ask us to delete or remove your personal data where you have
            successfully objected to processing (see below), where we may have
            processed your information unlawfully or where we are required to
            erase your personal data to comply with local law. Note, however,
            that we may not always be able to comply with your request of
            erasure for specific legal reasons which will be notified to you, if
            applicable, at the time of your request.
          </p>
          <p>
            <strong>Object to processing</strong> of your personal data where we
            are relying on a legitimate interest (or those of a third party) and
            there is something about your situation which makes you want to
            object to processing on this ground as you feel it impacts on your
            fundamental rights and freedoms. You also have the right to object
            where we are processing your personal data for direct marketing
            purposes. In some cases, we may demonstrate that we have compelling
            legitimate grounds to process your information which override your
            rights and freedoms.
          </p>
          <p>
            <strong>Request restriction of processing</strong> of your personal
            data. This enables you to ask us to suspend the processing of your
            personal data in the following scenarios:
          </p>
          <ul>
            <li>If you want us to establish the data's accuracy.</li>
            <li>
              Where our use of the data is unlawful, but you do not want us to
              erase it.
            </li>
            <li>
              Where you need us to hold the data even if we no longer require it
              as you need it to establish, exercise or defend legal claims.
            </li>
            <li>
              You have objected to our use of your data, but we need to verify
              whether we have overriding legitimate grounds to use it.
            </li>
          </ul>
          <p>
            <strong>Request the transfer</strong> of your personal data to you
            or to a third party. We will provide to you, or a third party you
            have chosen, your personal data in a structured, commonly used,
            machine-readable format. Note that this right only applies to
            automated information which you initially provided consent for us to
            use or where we used the information to perform a contract with you.
          </p>
          <p>
            <strong>Withdraw consent</strong> at any time where we are relying
            on consent to process your personal data. Nexus Users can manage
            some consents given through the Nexus Account settings. However,
            this will not affect the lawfulness of any processing carried out
            before you withdraw your consent. If you withdraw your consent, we
            may not be able to provide certain products or services to you. We
            will advise you if this is the case at the time you withdraw your
            consent.
          </p>
          <p>
            If you wish to exercise any of the rights set out above, please
            <a
              href="https://www.nexus-dt.com/contact-us"
              target="_blank"
              rel="noreferrer"
            >
              contact us
            </a>{" "}
            and/or check you Nexus Account settings.
          </p>
          <h3>No fee usually required</h3>
          <p>
            You will not have to pay a fee to access your personal data (or to
            exercise any of the other rights). However, we may charge a
            reasonable fee if your request is clearly unfounded, repetitive or
            excessive. Alternatively, we could refuse to comply with your
            request in these circumstances.
          </p>
          <h3>What we may need from you</h3>
          <p>
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access your personal
            data (or to exercise any of your other rights). We may also contact
            you to ask you for further information in relation to your request
            to speed up our response.
          </p>
          <h3>Time limit to respond</h3>
          <p>
            We try to respond to all legitimate requests within one month.
            Occasionally it could take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated.
          </p>
        </>
      );
    default:
      return null;
  }
};
