export const mainTextColor = "#182c47";
export const accentColor = "#8ADEC6";
export const borderColor = "#e3e5e8";
export const borderAccentColor = "#32B38E";
export const alertColor = "#D40033";
export const bgColor = "#f8f8f8";
export const bgInput = "#f3fcf9";
export const white = "#fff";
export const highlightColor = "#DBF0F0";

export const headerHeight = "118";
