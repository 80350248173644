import * as React from "react";
import s from "./Form.module.scss";
import cn from "classnames";

type Props = {
  children: React.ReactNode;
  className?: string;
  blockLine?: boolean;
  isRight?: boolean;
};

export const FieldsGroup = ({
  children,
  className,
  blockLine = false,
  isRight,
}: Props) => {
  return (
    <div
      className={cn(
        s.group,
        isRight && s.right,
        className,
        blockLine && s.blockLine
      )}
    >
      {children}
    </div>
  );
};
