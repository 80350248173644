const API_URL =
  process.env.REACT_APP_API_URL ?? "https://gsttapi.jelvix.dev/api";

export const AppConfig = {
  API_URL,
};

export const Local = {
  TOKEN: "act",
  REFRESH_TOKEN: "rt",
  FIREBASE_TOKEN: "fbt",
};
