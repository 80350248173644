import {
  getAnswersQuestAction,
  getOperationSummaryAction,
  getPostOperationAction,
  getPreOperationAction,
  getQuestionsQuestAction,
  getStaticQuestAction,
  updateOperationSummaryAction,
  updatePostOperationAction,
  updatePreOperationAction,
  updateQuestionsQuestAction,
} from "actions/questionnaireActions";
import {
  GET_QUESTIONS_QUEST,
  GET_CATEGORIES_QUEST,
  UPDATE_QUESTIONS_QUEST,
  GET_ANSWERS_QUEST,
  GET_OPERATION_SUMMARY,
  UPDATE_OPERATION_SUMMARY,
  GET_PRE_OPERATION,
  UPDATE_PRE_OPERATION,
  GET_POST_OPERATION,
  UPDATE_POST_OPERATION,
  GET_STATIC_QUEST,
} from "const";
import { HttpResp, httpApi, makeAction, encodeDataToUrl } from "helpers";
import { ICategoryQuest } from "interfaces";
import { call, put, takeLatest } from "redux-saga/effects";

function* getCategoriesQuest() {
  try {
    const res: HttpResp<ICategoryQuest> = yield call(httpApi, {
      partUrl: "/questionnaires/category",
      method: "GET",
    });

    if (res && !res.error) {
      yield put(makeAction(GET_CATEGORIES_QUEST.SUCCESS, res));
    } else {
      yield put(makeAction(GET_CATEGORIES_QUEST.FAILURE, res));
    }
  } catch (error) {
    yield put(makeAction(GET_CATEGORIES_QUEST.FAILURE, error));
  }
}

function* getQuestionsQuest({
  payload,
}: ReturnType<typeof getQuestionsQuestAction>) {
  try {
    const res: HttpResp = yield call(httpApi, {
      partUrl: `/questionnaires/category/${payload}`,
      method: "GET",
    });

    if (res && !res.error) {
      yield put(makeAction(GET_QUESTIONS_QUEST.SUCCESS, res));
    } else {
      yield put(makeAction(GET_QUESTIONS_QUEST.FAILURE, res));
    }
  } catch (error) {
    yield put(makeAction(GET_QUESTIONS_QUEST.FAILURE, error));
  }
}

function* getAnswersQuest({
  payload,
}: ReturnType<typeof getAnswersQuestAction>) {
  try {
    const res: HttpResp = yield call(httpApi, {
      partUrl: `/admin/questionnaire-answer?${encodeDataToUrl({
        ...payload,
      })}`,
      method: "GET",
    });

    if (res && !res.error) {
      yield put(makeAction(GET_ANSWERS_QUEST.SUCCESS, res));
    } else {
      yield put(makeAction(GET_ANSWERS_QUEST.FAILURE, res));
    }
  } catch (error) {
    yield put(makeAction(GET_ANSWERS_QUEST.FAILURE, error));
  }
}

function* getStaticQuest({ payload }: ReturnType<typeof getStaticQuestAction>) {
  try {
    const res: HttpResp = yield call(httpApi, {
      partUrl: `/admin/questionnaires/static?${encodeDataToUrl({
        ...payload,
      })}`,
      method: "GET",
    });

    if (res && !res.error) {
      yield put(makeAction(GET_STATIC_QUEST.SUCCESS, res));
    } else {
      yield put(makeAction(GET_STATIC_QUEST.FAILURE, res));
    }
  } catch (error) {
    yield put(makeAction(GET_STATIC_QUEST.FAILURE, error));
  }
}

function* updateQuestionsQuest({
  payload,
}: ReturnType<typeof updateQuestionsQuestAction>) {
  const { user_uuid, data } = payload;

  try {
    const res: HttpResp = yield call(httpApi, {
      partUrl: `/admin/questionnaires/answer?${encodeDataToUrl({
        user_uuid,
      })}`,
      method: "POST",
      data,
    });

    if (res && !res.error) {
      yield put(makeAction(UPDATE_QUESTIONS_QUEST.SUCCESS, res));
    } else {
      yield put(makeAction(UPDATE_QUESTIONS_QUEST.FAILURE, res));
    }
  } catch (error) {
    yield put(makeAction(UPDATE_QUESTIONS_QUEST.FAILURE, error));
  }
}

function* getOperationSummary({
  payload,
}: ReturnType<typeof getOperationSummaryAction>) {
  try {
    const res: HttpResp = yield call(httpApi, {
      partUrl: `/admin/operation/summary?${encodeDataToUrl({
        user_uuid: payload,
      })}`,
      method: "GET",
    });

    if (res && !res.error) {
      yield put(makeAction(GET_OPERATION_SUMMARY.SUCCESS, res));
    } else {
      yield put(makeAction(GET_OPERATION_SUMMARY.FAILURE, res));
    }
  } catch (error) {
    yield put(makeAction(GET_OPERATION_SUMMARY.FAILURE, error));
  }
}

function* updateOperationSummary({
  payload,
}: ReturnType<typeof updateOperationSummaryAction>) {
  const { user_uuid, data } = payload;

  try {
    const res: HttpResp = yield call(httpApi, {
      partUrl: `/admin/operation/summary?${encodeDataToUrl({
        user_uuid,
      })}`,
      method: "POST",
      formData: true,
      data,
    });

    if (res && !res.error) {
      yield put(makeAction(UPDATE_OPERATION_SUMMARY.SUCCESS, res));
    } else {
      yield put(makeAction(UPDATE_OPERATION_SUMMARY.FAILURE, res));
    }
  } catch (error) {
    yield put(makeAction(UPDATE_OPERATION_SUMMARY.FAILURE, error));
  }
}

function* getPreOperation({
  payload,
}: ReturnType<typeof getPreOperationAction>) {
  try {
    const res: HttpResp = yield call(httpApi, {
      partUrl: `/admin/operation/pre?${encodeDataToUrl({
        user_uuid: payload,
      })}`,
      method: "GET",
    });

    if (res && !res.error) {
      yield put(makeAction(GET_PRE_OPERATION.SUCCESS, res));
    } else {
      yield put(makeAction(GET_PRE_OPERATION.FAILURE, res));
    }
  } catch (error) {
    yield put(makeAction(GET_PRE_OPERATION.FAILURE, error));
  }
}

function* updatePreOperation({
  payload,
}: ReturnType<typeof updatePreOperationAction>) {
  const { user_uuid, data } = payload;

  try {
    const res: HttpResp = yield call(httpApi, {
      partUrl: `/admin/operation/pre?${encodeDataToUrl({
        user_uuid,
      })}`,
      method: "POST",
      formData: true,
      data,
    });

    if (res && !res.error) {
      yield put(makeAction(UPDATE_PRE_OPERATION.SUCCESS, res));
    } else {
      yield put(makeAction(UPDATE_PRE_OPERATION.FAILURE, res));
    }
  } catch (error) {
    yield put(makeAction(UPDATE_PRE_OPERATION.FAILURE, error));
  }
}

function* getPostOperation({
  payload,
}: ReturnType<typeof getPostOperationAction>) {
  try {
    const res: HttpResp = yield call(httpApi, {
      partUrl: `/admin/operation/post?${encodeDataToUrl({
        user_uuid: payload,
      })}`,
      method: "GET",
    });

    if (res && !res.error) {
      yield put(makeAction(GET_POST_OPERATION.SUCCESS, res));
    } else {
      yield put(makeAction(GET_POST_OPERATION.FAILURE, res));
    }
  } catch (error) {
    yield put(makeAction(GET_POST_OPERATION.FAILURE, error));
  }
}

function* updatePostOperation({
  payload,
}: ReturnType<typeof updatePostOperationAction>) {
  const { user_uuid, data } = payload;

  try {
    const res: HttpResp = yield call(httpApi, {
      partUrl: `/admin/operation/post?${encodeDataToUrl({
        user_uuid,
      })}`,
      method: "POST",
      formData: true,
      data,
    });

    if (res && !res.error) {
      yield put(makeAction(UPDATE_POST_OPERATION.SUCCESS, res));
    } else {
      yield put(makeAction(UPDATE_POST_OPERATION.FAILURE, res));
    }
  } catch (error) {
    yield put(makeAction(UPDATE_POST_OPERATION.FAILURE, error));
  }
}

export function* questionnaireWatcher() {
  yield takeLatest(GET_CATEGORIES_QUEST.PENDING, getCategoriesQuest);
  yield takeLatest(GET_QUESTIONS_QUEST.PENDING, getQuestionsQuest);
  yield takeLatest(GET_ANSWERS_QUEST.PENDING, getAnswersQuest);
  yield takeLatest(GET_STATIC_QUEST.PENDING, getStaticQuest);
  yield takeLatest(UPDATE_QUESTIONS_QUEST.PENDING, updateQuestionsQuest);
  yield takeLatest(GET_OPERATION_SUMMARY.PENDING, getOperationSummary);
  yield takeLatest(UPDATE_OPERATION_SUMMARY.PENDING, updateOperationSummary);
  yield takeLatest(GET_PRE_OPERATION.PENDING, getPreOperation);
  yield takeLatest(UPDATE_PRE_OPERATION.PENDING, updatePreOperation);
  yield takeLatest(GET_POST_OPERATION.PENDING, getPostOperation);
  yield takeLatest(UPDATE_POST_OPERATION.PENDING, updatePostOperation);
}
