import Immutable from "immutable";
import { handleActions } from "redux-actions";
import { makeCombinedAction } from "helpers";
import {
  CREATE_PATIENT,
  GET_PATIENTS,
  GET_AGES,
  REMOVE_PATIENT,
  UPDATE_PATIENT,
  GET_ETHNICITY,
  GET_ETHNICITIES,
  GET_PATIENTS_AMOUNT,
  GET_PATIENT_ROOM,
  RESET_PATIENT_ROOM,
  TOGGLE_STATUS_PATIENT,
  GET_PATIENT_ROOM_PHOTO,
  GET_PATIENT_ROOM_MESSAGING,
  GET_RELIGIONS,
  GET_HEALTH_PASSPORT_REPORT,
  DATE_FORMAT,
} from "const";
import { Ages, IAgesResponse, IPatients, IPatientsResponse } from "interfaces";
import { RESET_TABLE } from "const/actionTypes/tableTypes";
import lightFormat from "date-fns/lightFormat";

const { PENDING, DONE } = makeCombinedAction(
  GET_PATIENTS_AMOUNT,
  GET_AGES,
  GET_ETHNICITIES,
  CREATE_PATIENT,
  UPDATE_PATIENT,
  REMOVE_PATIENT,
  TOGGLE_STATUS_PATIENT,
  GET_HEALTH_PASSPORT_REPORT
);

export interface IPatientsList {
  count: number;
  result: IPatients[];
}

export interface IPatientsReducerState {
  isLoading: boolean;
  ethnicityGroups: [];
  religions: string[];
  patientsAmount: number;
  ages: IAgesResponse;
  ethnicities: {};
  patientsList: IPatientsList;
  patientRoom: {};
  photoInRoom: string;
  messagingInfoInRoom: {};
}

const initialState = Immutable.fromJS({
  isLoading: false,
  ethnicityGroups: [],
  religions: [],
  patientsAmount: 0,
  ages: {
    [Ages.ages40_50]: 0,
    [Ages.ages51_60]: 0,
    [Ages.ages61_70]: 0,
    [Ages.ages71_]: 0,
  },
  ethnicities: {},
  patientsList: {
    count: 0,
    result: [],
  },
  patientRoom: {},
  photoInRoom: "",
  messagingInfoInRoom: {},
  reportHP: "",
} as IPatientsReducerState);

const patientsReducer = handleActions(
  {
    [PENDING]: (state: any) => {
      return state.set("isLoading", true);
    },
    [`${DONE}`]: (state: any) => {
      return state.set("isLoading", false);
    },
    [GET_PATIENTS.SUCCESS]: (state: any, { payload }) => {
      const data = payload?.result?.map((row: IPatientsResponse) => ({
        ...row,
        ethnicity: row?.ethnicity?.group,
        religion: row?.religion?.religion,
        gender: row?.gender?.gender,
        dateOfBirthString: lightFormat(new Date(row?.dateOfBirth), DATE_FORMAT),
      }));

      return state.set(
        "patientsList",
        Immutable.fromJS({
          count: payload.count,
          result: data,
        })
      );
    },
    [GET_PATIENTS_AMOUNT.SUCCESS]: (state: any, { payload }) => {
      return state.set("patientsAmount", Immutable.fromJS(payload));
    },
    [GET_AGES.SUCCESS]: (state: any, { payload }) => {
      return state.set("ages", Immutable.fromJS(payload));
    },
    [GET_ETHNICITIES.SUCCESS]: (state: any, { payload }) => {
      return state.set("ethnicities", Immutable.fromJS(payload));
    },
    [GET_ETHNICITY.SUCCESS]: (state: any, { payload }) => {
      return state.set(
        "ethnicityGroups",
        Immutable.fromJS(Object.values(payload))
      );
    },
    [GET_RELIGIONS.SUCCESS]: (state: any, { payload }) => {
      const data = Object.values(payload).map(({ uuid, religion }: any) => {
        return { uuid, name: religion };
      });
      return state.set("religions", Immutable.fromJS(data));
    },
    [GET_PATIENT_ROOM.SUCCESS]: (state: any, { payload }) => {
      const data = {
        ...payload,
        dateOfBirthString: lightFormat(
          new Date(payload?.dateOfBirth),
          DATE_FORMAT
        ),
      };
      return state.set("patientRoom", Immutable.fromJS(data));
    },
    [GET_PATIENT_ROOM_PHOTO.SUCCESS]: (state: any, { payload }) => {
      return state.set("photoInRoom", Immutable.fromJS(payload));
    },
    [GET_PATIENT_ROOM_MESSAGING.SUCCESS]: (state: any, { payload }) => {
      return state.set("messagingInfoInRoom", Immutable.fromJS(payload));
    },
    [RESET_PATIENT_ROOM]: (state: any) => {
      return state
        .set("patientRoom", Immutable.fromJS({}))
        .set("photoInRoom", Immutable.fromJS(""))
        .set("messagingInfoInRoom", Immutable.fromJS({}));
    },
    [RESET_TABLE]: (state: any) => {
      return state.set(
        "patientsList",
        Immutable.fromJS({
          count: 0,
          result: [],
        })
      );
    },
  },
  initialState
);

export default patientsReducer;
