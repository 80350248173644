import { makeActionType } from "helpers";

export const GET_CATEGORIES_QUEST = makeActionType("GET_CATEGORIES_QUEST");
export const GET_QUESTIONS_QUEST = makeActionType("GET_QUESTIONS_QUEST");
export const GET_ANSWERS_QUEST = makeActionType("GET_ANSWERS_QUEST");
export const GET_STATIC_QUEST = makeActionType("GET_STATIC_QUEST");

export const UPDATE_QUESTIONS_QUEST = makeActionType("UPDATE_QUESTIONS_QUEST");

export const CLEAR_QUESTIONS_QUEST = "CLEAR_QUESTIONS_QUEST";

export const GET_OPERATION_SUMMARY = makeActionType("GET_OPERATION_SUMMARY");
export const UPDATE_OPERATION_SUMMARY = makeActionType(
  "UPDATE_OPERATION_SUMMARY"
);
export const GET_PRE_OPERATION = makeActionType("GET_PRE_OPERATION");
export const UPDATE_PRE_OPERATION = makeActionType("UPDATE_PRE_OPERATION");
export const GET_POST_OPERATION = makeActionType("GET_POST_OPERATION");
export const UPDATE_POST_OPERATION = makeActionType("UPDATE_POST_OPERATION");
export const CLEAR_OPERATION = "CLEAR_OPERATION";
