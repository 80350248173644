export const Pages = {
  privacy: "Privacy",
  terms: "Terms",
};

export const Heading = {
  privacy: "Nexus App- Health Pilot Privacy Notice",
  terms:
    "NEXUS DIGITAL TECHNOLOGY LIMITED (Nexus): ACCOUNT TERMS AND USER LICENCE",
};

export const privacy = [
  {
    id: 1,
    title: "Introduction",
  },
  {
    id: 2,
    title: "Important information and who we are",
  },
  {
    id: 3,
    title: "The information we collect about you",
  },
  {
    id: 4,
    title: "How is your personal data collected?",
  },
  {
    id: 5,
    title: "Affiliates and Partners",
  },
  {
    id: 6,
    title: "Third-party links",
  },
  {
    id: 7,
    title: "How we use your personal data",
  },
  {
    id: 8,
    title: "Disclosures of your personal data",
  },
  {
    id: 9,
    title: "International transfers",
  },
  {
    id: 10,
    title: "Your legal rights",
  },
];

export const terms = [
  {
    id: 1,
    title: "Introduction",
  },
  {
    id: 2,
    title: "Your account",
  },
  {
    id: 3,
    title: "Services",
  },
  {
    id: 4,
    title: "Affiliates",
  },
  {
    id: 5,
    title: "Our liability",
  },
  {
    id: 6,
    title: "License Restrictions",
  },
];
