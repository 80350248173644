export const StatusCode = {
  OFFLINE: 0,
  OK: 200,
  ACCEPTED: 202,
  MULTIPLY_CHOICE: 300,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  INTERNAL_ERROR: 500,
  TOKEN_EXPIRED: 307,
};
