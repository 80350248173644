import Immutable from "immutable";
import { handleActions } from "redux-actions";
import { makeCombinedAction } from "helpers";
import {
  GET_QUESTIONS_QUEST,
  GET_CATEGORIES_QUEST,
  CLEAR_QUESTIONS_QUEST,
  GET_ANSWERS_QUEST,
  GET_OPERATION_SUMMARY,
  GET_PRE_OPERATION,
  GET_POST_OPERATION,
  CLEAR_OPERATION,
  GET_STATIC_QUEST,
} from "const/actionTypes";
import { IAnswerResponce, ICategoryQuest, IQuestion } from "interfaces";

const { PENDING, DONE, FAILURE } = makeCombinedAction(
  GET_CATEGORIES_QUEST,
  GET_QUESTIONS_QUEST,
  GET_ANSWERS_QUEST,
  GET_STATIC_QUEST,
  GET_OPERATION_SUMMARY,
  GET_PRE_OPERATION,
  GET_POST_OPERATION
);

export interface IQuestionnaireReducerState {
  isLoading: boolean;
  categories: ICategoryQuest[];
  questions: IQuestion[];
  answers: IAnswerResponce[] | undefined;
  static: {};
  operationSummary: {};
  preOperation: {};
  postOperation: {};
}

const initialState = Immutable.fromJS({
  isLoading: false,
  categories: [],
  questions: [],
  answers: undefined,
  static: {},
  operationSummary: {},
  preOperation: {},
  postOperation: {},
} as IQuestionnaireReducerState);

const questionnaireReducer = handleActions(
  {
    [PENDING]: (state: any) => {
      return state.set("isLoading", true);
    },
    [`${FAILURE}||${DONE}`]: (state: any) => {
      return state.set("isLoading", false);
    },
    [GET_CATEGORIES_QUEST.SUCCESS]: (state: any, { payload }) => {
      return state.set("categories", Immutable.fromJS(Object.values(payload)));
    },
    [GET_QUESTIONS_QUEST.SUCCESS]: (state: any, { payload }) => {
      return state.set("questions", Immutable.fromJS(Object.values(payload)));
    },
    [GET_ANSWERS_QUEST.SUCCESS]: (state: any, { payload }) => {
      return state.set("answers", Immutable.fromJS(Object.values(payload)));
    },
    [GET_STATIC_QUEST.SUCCESS]: (state: any, { payload }) => {
      return state.set("static", Immutable.fromJS(Object.entries(payload)));
    },
    [CLEAR_QUESTIONS_QUEST]: (state: any) => {
      return state
        .set("questions", [])
        .set("answers", undefined)
        .set("static", {});
    },
    [GET_OPERATION_SUMMARY.SUCCESS]: (state: any, { payload }) => {
      return state.set("operationSummary", Immutable.fromJS(payload));
    },
    [GET_PRE_OPERATION.SUCCESS]: (state: any, { payload }) => {
      return state.set("preOperation", Immutable.fromJS(payload));
    },
    [GET_POST_OPERATION.SUCCESS]: (state: any, { payload }) => {
      return state.set("postOperation", Immutable.fromJS(payload));
    },
    [CLEAR_OPERATION]: (state: any) => {
      return state
        .set("operationSummary", {})
        .set("preOperation", {})
        .set("postOperation", {});
    },
  },
  initialState
);

export default questionnaireReducer;
