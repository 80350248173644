export const downloadXls = (response: any) => {
  const outputFilename = `${Date.now()}.xls`;
  const url = URL.createObjectURL(
    new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8",
    })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", outputFilename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
