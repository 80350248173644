export const convertImageBlobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result as string));
    reader.readAsDataURL(blob);
  });
};

export const getISODateFormat = (date: Date) => {
  return new Date(date.toString().split("GMT")[0] + " UTC").toISOString();
};
