import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import { IFormInput } from "interfaces/form";
import { Icon } from "components/common/Icon";
import { FieldWrapper } from "../FieldWrapper";
import cn from "classnames";
import { getNumberTypeValue } from "helpers";

interface IFormTextInputProps extends IFormInput {
  className?: string;
  inputMask?: string;
  icon?: string;
  maxLength?: number;
}

export const FormTextInput = React.forwardRef<any, IFormTextInputProps>(
  (props, ref) => {
    const {
      label,
      className = "",
      placeholder,
      type = "text",
      inputMask,
      error = "Error",
      name,
      value,
      icon,
      disabled,
      maxLength = 50,
      onChange,
      ...rest
    } = props;

    const handleChange = (e: any) => {
      if (type === "number") {
        onChange(getNumberTypeValue(e.target.value));
        return;
      }

      onChange(e);
    };

    return (
      <FieldWrapper
        className={cn(className, disabled && "disabled")}
        error={error}
        label={label}
        id={name}
      >
        {inputMask ? (
          <InputMask
            mask={inputMask}
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            disabled={disabled}
          >
            {() => (
              <TextField
                id={name}
                type={type}
                placeholder={placeholder}
                fullWidth
                disabled={disabled}
                ref={ref}
                {...rest}
              />
            )}
          </InputMask>
        ) : (
          <TextField
            id={name}
            type={type === "number" ? "text" : type}
            placeholder={placeholder}
            variant="outlined"
            {...rest}
            value={value}
            fullWidth
            onChange={handleChange}
            inputProps={{
              maxLength: maxLength,
            }}
            InputProps={{
              startAdornment: (
                <>
                  {icon && (
                    <InputAdornment position="end">
                      <Icon icon={icon} />
                    </InputAdornment>
                  )}
                </>
              ),
            }}
            ref={ref}
            disabled={disabled}
          />
        )}
      </FieldWrapper>
    );
  }
);
